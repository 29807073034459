<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
</script>

<template>
  <div class="basic-legal-footer" :class="{'is-desktop': getIsDesktop}">
    <img class="snapdragon-logo" src="../../public/assets/imgs/snapdragon-hero-logo.png">
    <div class="footer-line-1">© Qualcomm Technologies, Inc. and/or its affiliated companies.</div>
    <div class="footer-line-2">
      <a href="https://www.qualcomm.com/site/terms-of-use" target="_blank" class="link">Terms of Use</a>
      <a href="https://www.qualcomm.com/site/privacy" target="_blank" class="link">Privacy</a>
      <a href="https://www.qualcomm.com/site/privacy/cookies" target="_blank" class="link">Cookie Policy</a>
      <button class="ot-sdk-show-settings link" id="ot-sdk-btn">Cookie Settings</button>

    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../sass/Settings";

.basic-legal-footer {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .snapdragon-logo {
    height: auto;
    width: vw(221);
  }
  .footer-line-1 {
    margin-top: vw(2);

    font-family: Montserrat;
    font-size: vw(10);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: vw(0.1);
    text-align: center;
    color: #626971;
  }
  .footer-line-2 {

    margin-top: vw(19);
    margin-bottom: vw(129);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: vw(20);

    .link {
      display: inline;
      font-family: Montserrat;
      font-size: vw(10);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: vw(0.1);
      color: #222;
    }
    .ot-sdk-show-settings {
      display: inline;
      font-family: Montserrat !important;
      font-size: vw(10) !important;
      font-weight: 500 !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.8 !important;
      letter-spacing: vw(0.1) !important;
      color: #222 !important;
      border: none !important;
      padding: 0 !important;
      background: transparent !important;
    }
  }

  &.is-desktop {
    align-items: flex-start;
    justify-content: flex-start;
    height: vh(146, $design-height-desktop);
    .snapdragon-logo {
      height: auto;
      width: vh(221, $design-height-desktop);
      margin-left: vh(18, $design-height-desktop);
    }
    .footer-line-1 {
      margin-top: vh(2, $design-height-desktop);
      font-size: vh(10, $design-height-desktop);
      line-height: 1.8;
      letter-spacing: vh(0.1, $design-height-desktop);
      text-align: left;
      margin-left: vh(32, $design-height-desktop);
    }
    .footer-line-2 {

      margin-top: vh(19, $design-height-desktop);

      display: flex;
      flex-direction: row;
      gap: vh(20, $design-height-desktop);
      margin-left: vh(32, $design-height-desktop);
      margin-bottom: 0;

      .link {
        display: inline;
        font-family: Montserrat;
        font-size: vh(10, $design-height-desktop);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: vh(0.1, $design-height-desktop);
        color: #222;
      }
      .ot-sdk-show-settings {
        display: inline;
        font-family: Montserrat !important;
        font-size: vh(10, $design-height-desktop) !important;
        font-weight: 500 !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: vh(0.1, $design-height-desktop) !important;
        color: #222 !important;
        border: none !important;
        padding: 0 !important;
        background: transparent !important;
      }
    }
  }
}

</style>
