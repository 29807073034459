///////////////////////////////////////////////////////////////////////////
/*
Name: 			extra-augmentation.js

Description: 	Allows you to create an additional, simple augmentation layer on top to the camera feed but below the other augmentation layer.

Usage:			- Call init in loadSequence.js
                - Call one of the methods such as addFadedCameraFeed
                - If you ever want to remove the additional augmentation, call clearAugmentation

                Highly encourage you to write more methods that add functionality. You can manipulate the canvas and its context (ctx) freely while initialized!

Author:			Christopher Poole

Last Updated:	3/9/2020

Version: 		1.0
*/
///////////////////////////////////////////////////////////////////////////

const extraAugmentation = {
  canvas: null,
  ctx: null,

  isInitialized: false,

  init() {
    this.canvas = document.createElement("canvas");
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    this.canvas.style.zIndex = "-2"; //NOTE: Not entirely sure this will work with PlayCanvas

    document.querySelector("body").appendChild(this.canvas);

    this.ctx = this.canvas.getContext("2d");

    this.isInitialized = true;
  },

  addFadedCameraFeed(opacity) {
    if (!this.isInitialized) return;

    this.ctx.globalAlpha = opacity;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.globalAlpha = 1.0;
  },
  clearAugmentation() {
    if (!this.isInitialized) return;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  },
};

export default extraAugmentation;
