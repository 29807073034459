export default {
  legalFooterActive({ commit }, target) {
    commit("setLegalFooterActive", target);
  },
  scanFailedReset({ commit }) {
    setTimeout(() => {
      //context.$store.commit("setScanned", true);
      commit("setScanning", false);
      commit("setScanFailed", false);
    }, 3000);
  },
  changeLocale({ commit, dispatch }, target) {
    commit("setLocale", target);
    dispatch("language/fetchLanguage");
  },
};
