<script setup>
import analytics from "@/js/analytics";
import {onMounted} from "vue";

const props = defineProps({
  hasCautionIcon: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "",
  },
  listItems: {
    type: Object,
    array: true,
    default: function () {
      return {
        itemText: "",
        imageSource: "",
        imageWidth: 0,
        imageHeight: 0,
      };
    },
  },

  isDesktop: {
    type: Boolean,
    default: false,
  },
  isMobileRotated: {
    type: Boolean,
    default: false,
  },
    analyticsEvent: {
      type: String,
      default: ""
    }
});
onMounted(function () {
  analytics.changePage(props.analyticsEvent);
})
</script>

<template>
  <div class="module-container">
    <div
      class="content-column"
      style="margin-bottom: 0"
      :class="{
        'is-desktop': props.isDesktop,
        'is-mobile-rotated': props.isMobileRotated,
      }"
    >
      <UncopyableImage
        class="image"
        src="../www/assets/imgs/icon-error.png"
        v-if="props.hasCautionIcon"
        :width="props.isDesktop ? 105 : 56"
        :height="props.isDesktop ? 105 : 56"
        :use-width-scaling="!props.isDesktop && !props.isMobileRotated"
        :use-desktop-proportion="props.isDesktop"
        :mobile-landscape="props.isMobileRotated"
        :class="{
          'is-desktop': props.isDesktop,
          'is-mobile-rotated': props.isMobileRotated,
        }"
      />
      <span
        class="text text-header"
        v-if="props.title !== ''"
        v-html="props.title"
        :class="{
          'is-desktop': props.isDesktop,
          'is-mobile-rotated': props.isMobileRotated,
        }"
      />
      <div
        class="content-column content-column-display-left"
        style="margin-bottom: 0"
        :class="{
          'is-desktop': props.isDesktop,
          'is-mobile-rotated': props.isMobileRotated,
        }"
      >
        <div v-for="object in props.listItems" v-bind:key="object">
          <div class="content-row" style="margin-bottom: 0">
            <span class="text text-list-item" v-html="object.itemText" />
            <UncopyableImage
              v-if="
                object.imageSource !== '' && object.imageSource !== undefined
              "
              class="image image-icon"
              :width="
                object.imageSource === ''
                  ? 0
                  : props.isDesktop
                  ? object.imageWidth / 2
                  : object.imageWidth
              "
              :height="
                object.imageSource === ''
                  ? 0
                  : props.isDesktop
                  ? object.imageHeight / 2
                  : object.imageHeight
              "
              :use-width-scaling="!props.isDesktop && !props.isMobileRotated"
              :src="object.imageSource"
              :use-desktop-proportion="props.isDesktop"
              :mobile-landscape="props.isMobileRotated"
              :class="{
                'is-desktop': props.isDesktop,
                'is-mobile-rotated': props.isMobileRotated,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  * {
    pointer-events: all;
  }
}
</style>
