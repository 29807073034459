///////////////////////////////////////////////////////////////////////////
/*
Name: 			analytics.js

Description:    How to Swap Analytics Type: change the method being assign to this.analyticsMethod

Usage:			import analytics from "./analytics.js"

*/
///////////////////////////////////////////////////////////////////////////

const analytics = {
  currentPage: "",
  timeStartedPage: 0,

  timers: {},

  //NOTE: You cannot set the method here, you have to set it in init
  analyticsMethod: null,


  init() {
    //this.startTimer("OverallDwellTime");
    console.log("init analytics");
    //window.addEventListener("focus", this.focusedPage.bind(this));
    //window.addEventListener("blur", this.blurPage.bind(this));

    //Change this.dataLayerAnalytics to whatever analytics method the project is using
    this.analyticsMethod = this.dataLayerAnalytics;
  },

  /*
  focusedPage() {
    this.startTimer("OverallDwellTime");
    this.timeStartedPage = Date.now();
  },
  blurPage() {
    if (this.currentPage !== "")
      this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);
    this.sendEventForTimer("DwellTime", "Overall", "OverallDwellTime");
  },

  //Analytics Method 1:
  googleAnalytics(category, action, label, value) {
    if (label != null && value != null)
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          label +
          ", " +
          value
      );
    else if (value != null)
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          value
      );
    else if (label != null)
      console.log(
        "analytics event - google analytics: " +
          category +
          ", " +
          action +
          ", " +
          label
      );
    else
      console.log(
        "analytics event - google analytics: " + category + ", " + action
      );
  },

   */

  //Analytics Method 2:
  dataLayerAnalytics(event, action, label, location) {
    console.log(
      "analytics event - data layer: " +
        event +
        ", " +
        action +
        ", " +
        label +
        ", " +
        location
    );
    /*
    adobeDataLayer.push({
    "event": "cta",
    "eventInfo": {
      "action": "click", //EXAMPLE: device_tile, Payment options, click
      "label": "<CTA TEXT >",
      "location": "<TARGET URL OF CTA>"
      }
    });

     */
    let eventInfo = {}
    if (action)
      eventInfo["action"] = action;
    if (label)
      eventInfo["label"] = label;
    if (location)
      eventInfo["location"] = location;

    window.adobeDataLayer.push({
      "event": event,
      "eventInfo": eventInfo
    });

  },

  /*
  pageVisitEvent(pageId) {
    this.analyticsMethod("PageVisit", pageId);
  },

  dwellTimeOnPageEvent(pageId, startTime) {
    let dwellOnLastPage = (Date.now() - startTime) / 1000;
    this.analyticsMethod("DwellTime", pageId, null, dwellOnLastPage);
  },

   */

  changePage(pageId) {
    if (pageId !== this.currentPage) {
      //Log the dwell time on the last page.
      //if (this.timeStartedPage !== 0)
        //this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);

      /*
      let orientation = "portrait";
      if (pageId == "device-rotated-page" || pageId == "desktop-page")
      {
        orientation = "landscape";
      }

       */

      window.page = pageId;

      //if (window.otConsentCookie == null)
        window.otConsentCookie = decodeURIComponent ((document.cookie.match(/^(?:.*;)?\s*OptanonConsent\s*=\s*([^;]+)(?:.*)?$/) || [, 'not-set'])[1]);

      if (otConsentCookie != null && otConsentCookie.indexOf(",C0002:1") > -1) { //C0002:1 stands for opt-in, C0002:0 stands for opt-out
        if (window.trackView)
          window.trackView();
      }
      //window.lastPage = this.currentPage;

      /*
      window.adobeDataLayer.push({
        event: "page-load",
        page: {
          device_orientation: orientation,
          page_name: pageId,
          previous_page_name: this.currentPage
        }
      });

       */

      this.currentPage = pageId;

      //this.pageVisitEvent(pageId);
      //this.timeStartedPage = Date.now();
    }
  },

  /*
  startTimer(timerId) {
    this.timers[timerId] = Date.now();
  },
  sendEventForTimer(category, action, timerId) {
    if (this.timers[timerId] != null) {
      let time = (Date.now() - this.timers[timerId]) / 1000;
      this.analyticsMethod(category, action, null, time);
    } else {
      console.log(
        "Tried to send an event for timer " +
          timerId +
          " which hasn't been started."
      );
    }
  },

   */
};

export default analytics;
