<script setup>
import {inject} from 'vue';
const vw = inject('vw');
const skipMotion = () => {
  window.gyroCheck = this.$refs["skip-motion"].value;
};
</script>

<template>
  <div class="module-container">
    <input
      ref="skip-motion"
      type="checkbox"
      class="skip-motion"
      v-on:click="skipMotion"
    />
    Toggle Motion Check
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/GlobalClasses";
@import "../../sass/Settings";

.module-container {
  @extend %absolute-fill;

  .as {
    width: 50%;
    height: vw(100) !important;
    background-color: white;
    pointer-events: auto;
    position: absolute;
    margin-top: 10%;
  }

  .skip-motion {
    pointer-events: auto;
  }
}
</style>
