<script setup>
import { ref, onMounted, computed } from "vue";
import photocapture from "../js/photoCapture.js";
import audioControl from "../js/audioControl.js";
import Share from "../components/ShareMenu.vue";
import { useStore } from "vuex";
import PhotoDisplay from "../components/PhotoDisplay.vue";
import {CameraMirroredMode} from "@/js/modules/enums";

import AR from "../js/playcanvasAR.js";
import sequencer from "../js/modules/sequencer.js";

import Shutter from "../components/modules/ShutterButton";

import carouselData from "../data/carouselData.json";

import { inject } from 'vue'
import InstructionsPage from "@/components/InstructionsPage.vue";
import analytics from "@/js/analytics";
const translate = inject('translate')
// grab state
const store = useStore();
const mediaURL = ref("");
const blobURL = ref("");
const mediaIsVideo = ref(false);
const mediaLoading = ref(carouselData.pages);
const pages = ref(carouselData.pages);
const instructionsOpen = ref(false);

let arrowsEnabled = ref(true);

onMounted(async () => {
  analytics.changePage("qc:unitedshirt:ar");
  arrowsEnabled.value = true;
  setTimeout(function () {
    arrowsEnabled.value = false;
  }, 4000);
});

const recenter = () => {
  AR.recenter();
};
const restart = () => {
  AR.app.fire("restart-app");
  store.commit("setPlaced", false);
  store.commit("setFirstTimeRun", false);
  store.commit("setPlaced", false);
};
const share = () => {

  audioControl.restartAudio("positive");
  store.commit("setShareOpen", true);
  analytics.analyticsMethod("cta", "click", "experience-share", "/share")
  analytics.changePage("qc:unitedshirt:share");
};

const instructions = () => {
  audioControl.restartAudio("positive");
  store.commit("instructionOpen", true);
  analytics.analyticsMethod("cta", "click", "instructions", "/instructions")
  analytics.changePage("qc:unitedshirt:instructions");
};
const closeInstructions = () => {
  audioControl.restartAudio("positive");
  store.commit("instructionOpen", false);
};
const takePhoto = () => {
  audioControl.restartAudio("camera");
  mediaLoading.value = true;
  //analytics.dataLayerEvent("Link Click", "ar_camera_photo", "Read", "Engagement");
  if (store.getters.getPhotoOpen) {
    store.commit("setPhotoOpen", false);
  } else {
    analytics.analyticsMethod("cta", "click", "picture-taken", "/photo-preview")
    photocapture.capture((url, blob) => {
      if (url != null) {
        mediaURL.value = url;
        blobURL.value = blob;
        mediaIsVideo.value = false;
        store.commit("setPhotoOpen", true);
        mediaLoading.value = false; //Theres no fail safe if photocapture fails, might want to fix that
      }
    });
  }
};

const startVideo = () => {
  console.log("starting video");
  AR.forceRenderFrame();
  AR.app.fire("start-video-recording", { onFinish: onVideoProcessed });
};
const stopVideo = () => {
  console.log("stopping video");
  mediaLoading.value = true;
  AR.app.fire("stop-video-recording");
  analytics.analyticsMethod("cta", "click", "video-taken", "/photo-preview")
};
const onVideoProcessed = ({ videoBlob }) => {
  console.log(videoBlob);
  mediaLoading.value = false;
  let file = new File([videoBlob], "recording.mp4", { type: "video/mp4" });
  mediaURL.value = URL.createObjectURL(file);
  blobURL.value = videoBlob;
  mediaIsVideo.value = true;
  store.commit("setPhotoOpen", true);
  //analytics.dataLayerEvent("Link Click", "ar_camera_video", "Read", "Engagement");
};

const isPhotoDisplayOpen = computed(() => store.getters.getPhotoOpen);
const shareOpen = computed(() => store.getters.getShareOpen);
const instructionOpen = computed(() => store.getters.instructionOpen);
const isNewSceneLoading = computed(() => store.getters.getIsNewSceneLoading);
const getIsIOS = computed(() => store.getters.getIsIOS);
</script>

<template>
  <div class="ar-container absolute-fill" id="ar">

    <transition name="fade">
      <div v-if="arrowsEnabled">
        <img src="../../public/assets/imgs/direction-arrows.png" class="directional-arrow arrows-left">
        <img src="../../public/assets/imgs/direction-arrows.png" class="directional-arrow arrows-right">
      </div>
    </transition>

    <img src="../../public/assets/imgs/icon-instruction.png" class="instructions-button" ontouchstart="" v-on:click="instructions"/>
    <img src="../../public/assets/imgs/icon-share-btn.png" class="share-button" ontouchstart="" v-on:click="share"/>
    <!-- Shutter Button -->
    <Shutter
        @take-photo="takePhoto"
        @start-video="startVideo"
        @stop-video="stopVideo"
        :allow-video="true"
    />

    <!-- Fullscreen popup modals -->
    <transition name="fade">
      <Share v-if="shareOpen" />
    </transition>
    <PhotoDisplay
        :mediaURL="mediaURL"
        :blobURL="blobURL"
        :isVideo="mediaIsVideo"
        :has-download="!getIsIOS"
        v-if="isPhotoDisplayOpen"
    />
    <transition name="fade">
      <InstructionsPage v-if = "instructionOpen" @close="closeInstructions" loaded = "loaded"/>
    </transition>
    <!--
    <transition name="fade">
      <div class="faded-background" v-if="isNewSceneLoading"></div>
    </transition>
    -->
  </div>
</template>
