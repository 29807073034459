///////////////////////////////////////////////////////////////////////////
/*
Name: 			interceptConsole.js

Description: 	Intercepts the console log so we can debug on phone
                WARNING: Im pretty sure it only gets the stuff from our app not imported scripts so be warned

Usage:			Debugging on the phone

*/
///////////////////////////////////////////////////////////////////////////

console.stdlog = console.log.bind(console);
window.debugLogs = [];
console.log = function () {
  window.debugLogs.push(Array.from(arguments));
  console.stdlog.apply(console, arguments);
  document.querySelector("body").dispatchEvent(new Event("debug-intercept"));
};

console.defaultError = console.error.bind(console);
console.error = function () {
  let argArray = Array.from(arguments);

  console.defaultError.apply(console, arguments);
  window.debugLogs.push("[ERROR]" + argArray);
  document.querySelector("body").dispatchEvent(new Event("debug-intercept"));
};

console.defaultWarn = console.warn.bind(console);
console.warn = function () {
  let argArray = Array.from(arguments);

  console.defaultWarn.apply(console, arguments);
  window.debugLogs.push("[WARNING]" + argArray);
  document.querySelector("body").dispatchEvent(new Event("debug-intercept"));
};

const debugConsole = {
  getFullList(newLineChar = "\n") {
    var log = "";
    for (var i = 0; i < window.debugLogs.length; i++) {
      log += window.debugLogs[i].toString() + newLineChar;
    }
    return log;
  },
  getLastLog() {
    if (window.debugLogs.length <= 0) return "";
    return window.debugLogs[window.debugLogs.length - 1].toString();
  },
};

export default debugConsole;
