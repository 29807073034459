<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { inject } from 'vue'
import analytics from "@/js/analytics";
const translate = inject('translate')

const isLinkCopied = ref(false);
const store = useStore();

const close = function () {
  store.commit("setShareOpen", false);
};
const copyLink = function (text) {
  // this.$store.dispatch("userInactionTimeout")
  if (!navigator.clipboard) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    var oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 100);

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand("copy");
    document.body.removeChild(el);
  } else {
    navigator.clipboard.writeText(text).then(
      () => {},
      () => {}
    );
  }

  isLinkCopied.value = true;

  setTimeout(() => {
    isLinkCopied.value = false;
  }, 2000);
};

const handleShare = function (type) {
  const shareUrl = translate("web-ar-url");
  const encodedUrl = encodeURI(shareUrl);
  let url = "";
  switch (type) {
    case "facebook": {
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
      //this.$store.dispatch('fireAnalytics', 'fb');
      analytics.analyticsMethod("cta", "click", "facebook", "https://www.facebook.com")
      break;
    }
    case "twitter": {
      const text = encodeURI(
        translate("share-twitter-body") +
          " " +
          shareUrl +
          "&hashtags=" +
          translate("twitter.hashtag")
      );
      url = `https://twitter.com/intent/tweet?text=${text}`;
      analytics.analyticsMethod("cta", "click", "x", "https://www.x.com")
      //this.$store.dispatch('fireAnalytics', 'tw');
      break;
    }
    case "email": {
      const subject = encodeURI(`${translate("share-email-title")}`);
      const body = encodeURI(`${translate("share-email-body")} ${shareUrl}`);
      url = `mailto:?subject=${subject}&body=${body}`;
      analytics.analyticsMethod("cta", "click", "email", "email")
      break;
    }
    case "copy": {
      copyLink(shareUrl);
      analytics.analyticsMethod("cta", "click", "copy-link", "copy-link")
      break;
    }
    default:
      break;
  }

  if (url) {
    window.open(url, "_blank");
  }
};
</script>
<template>
  <div class="share-container">
    <div class="faded-background absolute-fill" @click="close" />
    <div class="share-background">
      <img src="../../public/assets/imgs/share-module-image.png" class="share-image"/>
      <img src="../../public/assets/imgs/bttn-close-2.png" class="close-button" @click="close"/>
      <div class="bottom-area">
        <div class="share-header snapdragon-header">SHARE THIS EXPERIENCE WITH OTHERS!</div>
        <div class="share-options">
          <div class="share-option" @click="handleShare('twitter')">
            <img src="../../public/assets/imgs/bttn-twitter.png" class="share-option-image" />
            <div class="outline"></div>
          </div>
          <div class="share-option" @click="handleShare('facebook')">
            <img src="../../public/assets/imgs/bttn-facebook.png" class="share-option-image" />
            <div class="outline"></div>
          </div>
          <div class="share-option" @click="handleShare('email')">
            <img src="../../public/assets/imgs/bttn-email.png" class="share-option-image" />
            <div class="outline"></div>
          </div>
          <div class="share-option" @click="handleShare('copy')">
            <img src="../../public/assets/imgs/bttn-copy-link.png" class="share-option-image" />
            <div class="outline"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
