<script setup>
import ArWrapper from "./ArWrapper.vue";
import LoadingWrapper from "./LoadingWrapper.vue";

import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import ScanWrapper from "@/views/ScanWrapper.vue";
import DebugMenu from "@/components/debug_modules/DebugMenu.vue";
import AR from "@/js/playcanvasAR";

const store = useStore();
const loaded = computed(() => store.getters.getLoaded);
const scanned = computed(() => store.getters.getScanned);
const placed = computed(() => store.getters.getPlaced);
const insidePortal = computed(() => store.getters.getInsidePortal);
const isFlashing = computed(() => store.getters.getFlashing);

const getAppFailed = computed(() => store.getters.getAppFailed);
const getWinOverlayEnabled = computed(() => store.getters.getWinOverlayEnabled);
const getResultsOpen = computed(() => store.getters.getResultsOpen);
const getBlockARTap = computed(() => store.getters.getBlockARTap);

const getIsDebugEnabled = computed(() => store.getters.getIsDebugEnabled);
const getLeavingPopupEnabled = computed(
    () => store.getters.getLeavingPopupEnabled
);
const allowGyroPrompt = computed(
    () => loaded && scanned && !getAppFailed && !placed
);

</script>

<template>
  <div
      style = "z-index: 2; pointer-events: none"
      class = "absolute-fill main-wrapper"
  >
    <transition name = "fade">
      <ArWrapper v-if = "loaded && insidePortal" />
    </transition>

    <transition name = "fade">
      <ScanWrapper v-if="loaded && !scanned"></ScanWrapper>
    </transition>


    <transition name = "camera-flash">
      <div v-if = "isFlashing" class = "camera-flash" />
    </transition>
    <transition name = "fade">
      <LoadingWrapper v-if = "!loaded && !getAppFailed" />
    </transition>
    <transition name = "fade">
      <Fail v-cloak v-if = "getAppFailed" />
    </transition>
    <DebugMenu></DebugMenu>
  </div>
</template>
