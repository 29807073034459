import { store } from "../main.js";

//returns proper style keyword for position anchoring
export const parsePosition = (position = "relative") => {
  let outPosition;
  let inputPosition = position.toLowerCase();
  switch (inputPosition) {
    default:
      outPosition = `relative`;
      break;
    case "static":
    case "absolute":
    case "fixed":
    case "relative":
    case "sticky":
    case "initial":
    case "inherit":
      outPosition = inputPosition;
      break;
  }

  return outPosition;
};

const calcVW = (width, designWidth = 375) => {
  return `${
    (parseFloat(width) / designWidth) * 100 * store.getters.getTrueVW
  }px`;
};
const calcVH = (height, designHeight = 674) => {
  return `${
    (parseFloat(height) / designHeight) * 100 * store.getters.getTrueVH
  }px`;
};

//returns calculated virtual width in pixel value
export const getVW = (
  width,
  useMobileProportion = true,
  mobileLandscape = false
) => {
  return calcVW(
    width,
    useMobileProportion
      ? mobileLandscape
        ? store.getters.getDesignHeightMobile
        : store.getters.getDesignWidthMobile
      : store.getters.getDesignWidthDesktop
  );
};
//returns calculated virtual height in pixel value
export const getVH = (
  height,
  useMobileProportion = true,
  mobileLandscape = false
) => {
  return calcVH(
    height,
    useMobileProportion
      ? mobileLandscape
        ? store.getters.getDesignWidthMobile
        : store.getters.getDesignHeightMobile
      : store.getters.getDesignHeightDesktop
  );
};

const parseDimension = (
  size = undefined,
  scaleToViewport = undefined,
  useMobileProportion = undefined,
  widthScaling = undefined,
  mobileLandscape = undefined,
  percentScaling = undefined
) => {
  if (scaleToViewport === undefined) scaleToViewport = true;

  if (useMobileProportion === undefined) useMobileProportion = true;

  if (widthScaling === undefined) widthScaling = true;

  if (mobileLandscape === undefined) mobileLandscape = false;

  if (percentScaling === undefined) percentScaling = false;

  return size !== undefined && !isNaN(parseFloat(size))
    ? scaleToViewport || percentScaling
      ? percentScaling
        ? `${size}%`
        : widthScaling
        ? getVW(size, useMobileProportion, mobileLandscape)
        : getVH(size, useMobileProportion, mobileLandscape)
      : `${size}px`
    : `auto`;
};

//returns JSON object containing style for element dimensions
export const parseElementDimensions = (
  parameters = {
    //element width and height
    width: 0,
    height: 0,

    //use mobile proportions?
    mobileProportion: true,

    //scale to viewport or use raw pixels?
    scaleToViewport: true,

    //does the horizontal dimension use width scaling?
    horizontalWidthScaling: true,
    //does the vertical dimension use width scaling?
    verticalWidthScaling: true,

    //is the mobile device in landscape mode?
    mobileLandscape: false,

    //does the horizontal dimension use percent scaling?
    widthPercentScaling: false,
    //does the vertical dimension use percent scaling?
    heightPercentScaling: false,

    //should I convert auto dimensions to fit-content?
    fitContent: true,
  }
) => {
  let fitContent =
    parameters.fitContent !== undefined ? parameters.fitContent : true;
  let width = parseDimension(
    parameters.width,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.horizontalWidthScaling,
    parameters.mobileLandscape,
    parameters.widthPercentScaling
  );
  if (fitContent && width === "auto") width = "fit-content";
  let height = parseDimension(
    parameters.height,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.verticalWidthScaling,
    parameters.mobileLandscape,
    parameters.heightPercentScaling
  );
  if (fitContent && height === "auto") height = "fit-content";
  return { width: width, height: height };
};

//returns JSON object containing style for element anchoring
export const parseAnchoring = (
  parameters = {
    //anchoring edges
    left: "auto",
    right: "auto",
    top: "auto",
    bottom: "auto",

    //use mobile proportion?
    mobileProportion: true,

    //scale to viewport or use raw pixels?
    scaleToViewport: true,

    //does the horizontal anchor use width scaling?
    horizontalAnchorWidthScaling: true,
    //does the vertical anchor use width scaling?
    verticalAnchorWidthScaling: true,

    //is the mobile device in landscape mode?
    mobileLandscape: false,

    //does the horizontal anchor use percantages?
    horizontalAnchorPercentScaling: false,
    // does the vertica anchor use percentages?
    verticalAnchorPercentScaling: false,
  }
) => {
  let top = parseDimension(
    parameters.top,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.verticalAnchorWidthScaling,
    parameters.mobileLandscape,
    parameters.verticalAnchorPercentScaling
  );
  let left = parseDimension(
    parameters.left,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.horizontalAnchorWidthScaling,
    parameters.mobileLandscape,
    parameters.horizontalAnchorPercentScaling
  );
  let right = parseDimension(
    parameters.right,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.horizontalAnchorWidthScaling,
    parameters.mobileLandscape,
    parameters.horizontalAnchorPercentScaling
  );
  let bottom = parseDimension(
    parameters.bottom,
    parameters.scaleToViewport,
    parameters.mobileProportion,
    parameters.verticalAnchorWidthScaling,
    parameters.mobileLandscape,
    parameters.verticalAnchorPercentScaling
  );
  return { top: top, left: left, right: right, bottom: bottom };
};

//returns JSON object containing style for external element margins
export const parseMargins = (
  parameters = {
    //margin values for all edges
    allMargin: 0,

    //if no all margin - horizontal and vertical margins (must use both to be considered valid)
    horizontalMargin: 0,
    verticalMargin: 0,

    //if no all margin or horizontal & vertical margin - individual margin values
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,

    //use mobile proportion?
    mobileProportion: true,

    //scale to viewport or use raw pixels?
    scaleToViewport: true,

    //does the horizontal margin use width scaling?
    horizontalMarginWidthScaling: true,
    //does the vertical margin use width scaling?
    verticalMarginWidthScaling: true,

    //does the overall anchor use width scaling
    allMarginWidthScaling: true,

    //is the mobile device in landscape mode?
    mobileLandscape: false,

    //does the horizontal margin use percent scaling?
    horizontalMarginPercentScaling: false,
    //does the vertical margin use percent scaling?
    verticalMarginPercentScaling: false,
    //does the overall margin use percent scaling?
    allMarginPercentScaling: false,
  }
) => {
  let marginOutput = "";
  if (parameters.allMargin !== undefined) {
    marginOutput = parseDimension(
      parameters.allMargin,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.allMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.allMarginPercentScaling
    );
  } else if (
    parameters.horizontalMargin !== undefined &&
    parameters.verticalMargin !== undefined
  ) {
    let vertical = parseDimension(
      parameters.verticalMargin,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalMarginPercentScaling
    );
    let horizontal = parseDimension(
      parameters.horizontalMargin,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalMarginPercentScaling
    );
    marginOutput = vertical + " " + horizontal;
  } else {
    let top = parseDimension(
      parameters.marginTop,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalMarginPercentScaling
    );
    top = top === "auto" ? "0px" : top;
    let left = parseDimension(
      parameters.marginLeft,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalMarginPercentScaling
    );
    left = left === "auto" ? "0px" : left;
    let right = parseDimension(
      parameters.marginRight,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalMarginPercentScaling
    );
    right = right === "auto" ? "0px" : right;
    let bottom = parseDimension(
      parameters.marginBottom,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalMarginWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalMarginPercentScaling
    );
    bottom = bottom === "auto" ? "0px" : bottom;
    marginOutput = top + " " + right + " " + bottom + " " + left;
  }

  return { margin: marginOutput };
};

//returns JSON object containing style for internal element padding
export const parsePadding = (
  parameters = {
    //padding value for all edges
    allPadding: 0,

    //if no all padding - horizontal and vertical padding (must use both to be considered valid)
    horizontalPadding: 0,
    verticalPadding: 0,

    //if no all padding or vertical & horizontal padding - individual padding values
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,

    //use mobile proportion?
    mobileProportion: true,

    //scale to viewport or use raw pixels?
    scaleToViewport: true,

    //does the horizontal padding use width scaling?
    horizontalPaddingWidthScaling: true,
    //does the vertical padding use width scaling?
    verticalPaddingWidthScaling: true,

    //does the overall padding use width scaling
    allPaddingWidthScaling: true,

    //is the mobile device in landscape mode?
    mobileLandscape: false,

    //does the horizontal padding use percent scaling?
    horizontalPaddingPercentScaling: false,
    //does the vertical padding use percent scaling?
    verticalPaddingPercentScaling: false,

    //does the overall padding use percent scaling?
    allPaddingPercentScaling: false,
  }
) => {
  let paddingOutput = "";
  if (parameters.allPadding !== undefined) {
    paddingOutput = parseDimension(
      parameters.allPadding,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.allPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.allPaddingPercentScaling
    );
  } else if (
    parameters.horizontalPadding !== undefined &&
    parameters.verticalPadding !== undefined
  ) {
    let vertical = parseDimension(
      parameters.verticalPadding,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalPaddingPercentScaling
    );
    let horizontal = parseDimension(
      parameters.horizontalPadding,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalPaddingPercentScaling
    );
    paddingOutput = vertical + " " + horizontal;
  } else {
    let top = parseDimension(
      parameters.paddingTop,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalPaddingPercentScaling
    );
    top = top === "auto" ? "0px" : top;
    let left = parseDimension(
      parameters.paddingLeft,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalPaddingPercentScaling
    );
    left = left === "auto" ? "0px" : left;
    let right = parseDimension(
      parameters.paddingRight,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.horizontalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.horizontalPaddingPercentScaling
    );
    right = right === "auto" ? "0px" : right;
    let bottom = parseDimension(
      parameters.paddingBottom,
      parameters.scaleToViewport,
      parameters.mobileProportion,
      parameters.verticalPaddingWidthScaling,
      parameters.mobileLandscape,
      parameters.verticalPaddingPercentScaling
    );
    bottom = bottom === "auto" ? "0px" : bottom;
    paddingOutput = top + " " + right + " " + bottom + " " + left;
  }

  return { padding: paddingOutput };
};

const flexDirection = [
  "unset",
  "column",
  "column-reverse",
  "row",
  "row-reverse",
  "initial",
  "inherit",
  "revert",
];

const justifyAlign = [
  "flex-start",
  "flex-end",
  "center",
  "space-between",
  "space-around",
  "space-evenly",
  "initial",
  "inherit",
];

export const parseFlex = (
  parameters = {
    flexDirection: "unset",
    justify: "inherit",
    align: "inherit",
  }
) => {
  if (
    parameters.flexDirection === undefined ||
    parameters.flexDirection === "inherit" ||
    !flexDirection.includes(parameters.flexDirection.toLowerCase())
  )
    return {};

  let justify =
    parameters.justify !== undefined &&
    justifyAlign.includes(parameters.justify.toLowerCase())
      ? parameters.justify.toLowerCase()
      : "center";
  let align =
    parameters.align !== undefined &&
    justifyAlign.includes(parameters.align.toLowerCase())
      ? parameters.align.toLowerCase()
      : "center";

  return {
    display: "flex",
    "flex-direction": parameters.flexDirection.toLowerCase(),
    "justify-content": justify,
    "justify-items": justify,
    "align-content": align,
    "align-items": align,
  };
};

//universal function that synthesizes all arguments and returns of parsePosition, parseElementDimensions, parseMargin, parsePadding, and parseAnchoring
export const parseElementStyle = (parameters = { position: "relative" }) => {
  let output = { position: parsePosition(parameters.position) };
  if (parameters.width !== undefined || parameters.height !== undefined) {
    let dimensions = parseElementDimensions(parameters);
    for (let attribute in dimensions) output[attribute] = dimensions[attribute];
  }
  if (
    parameters.top !== undefined ||
    parameters.left !== undefined ||
    parameters.right !== undefined ||
    parameters.bottom !== undefined
  ) {
    let anchoring = parseAnchoring(parameters);
    for (let attribute in anchoring) output[attribute] = anchoring[attribute];
  }
  if (
    parameters.allMargin !== undefined ||
    (parameters.horizontalMargin !== undefined &&
      parameters.verticalMargin !== undefined) ||
    parameters.marginTop !== undefined ||
    parameters.marginLeft !== undefined ||
    parameters.marginRight !== undefined ||
    parameters.marginBottom !== undefined
  ) {
    let margin = parseMargins(parameters);
    for (let attribute in margin) output[attribute] = margin[attribute];
  }
  if (
    parameters.allPadding !== undefined ||
    (parameters.horizontalPadding !== undefined &&
      parameters.verticalPadding !== undefined) ||
    parameters.paddingTop !== undefined ||
    parameters.paddingLeft !== undefined ||
    parameters.paddingRight !== undefined ||
    parameters.paddingBottom !== undefined
  ) {
    let padding = parseMargins(parameters);
    for (let attribute in padding) output[attribute] = padding[attribute];
  }
  if (parameters.flexDirection !== undefined) {
    let flex = parseFlex(parameters);
    for (let attribute in flex) output[attribute] = flex[attribute];
  }
  return output;
};

const verticalAnchoring = ["top", "center", "bottom"];
const horizontalAnchoring = ["left", "center", "right"];

//injects style to make the element fill its container
export const parseContainerFill = (
  parameters = {
    //center element in its container?
    verticalAnchor: "center",
    horizontalAnchor: "center",
    //preserve the aspect ratio of the element? If true, uses object fit cover
    preserveAspectRatio: false,
  }
) => {
  let transformValue = "";
  let top = "auto";
  let left = "auto";
  let right = "auto";
  let bottom = "auto";
  let verticalAnchor =
    parameters.verticalAnchor !== undefined &&
    verticalAnchoring.includes(parameters.verticalAnchor.toLowerCase())
      ? parameters.verticalAnchor.toLowerCase()
      : "center";
  let horizontalAnchor =
    parameters.horizontalAnchor !== undefined &&
    horizontalAnchoring.includes(parameters.horizontalAnchor.toLowerCase())
      ? parameters.horizontalAnchor.toLowerCase()
      : "center";
  let preserveAspectRatio =
    parameters.preserveAspectRatio !== undefined
      ? parameters.preserveAspectRatio
      : false;

  if (verticalAnchor === "center" && horizontalAnchor === "center") {
    top = "50%";
    left = "50%";
    transformValue = "translate(-50%, -50%)";
  } else if (verticalAnchor === "center") {
    top = "50%";
    transformValue = "translateY(-50%)";
  } else if (horizontalAnchor === "center") {
    left = "50%";
    transformValue = "translateX(-50%)";
  } else transformValue = "translate(0, 0)";
  if (verticalAnchor === "top") top = "0px";
  if (verticalAnchor === "bottom") bottom = "0px";
  if (horizontalAnchor === "left") left = "0px";
  if (horizontalAnchor === "right") right = "0px";

  return preserveAspectRatio
    ? {
        width: "100%",
        height: "100%",
        "object-fit": "cover",
        transform: transformValue,
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        position: "absolute",
      }
    : {
        width: "100%",
        height: "100%",
        transform: transformValue,
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        position: "absolute",
      };
};
