<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import LandingView from "./views/LandingWrapper.vue";
import AppWrapper from "./views/AppWrapper.vue";
import Fail from "./views/fail_modules/FailWrapper.vue";
import audioControl from "./js/audioControl.js";
import loadSequence from "./js/loadSequence.js";
import analytics from "@/js/analytics";


// grab state
const store = useStore();

function handleContinue() {
  analytics.analyticsMethod("cta", "click", "LET'S GO", "/permissions")

  analytics.changePage("qc:unitedshirt:permissions");
  audioControl.restartAudio("positive");
  setTimeout(function () {
    audioControl.restartAudio("starttap");
    store.commit("setIsLanding", false);
  }, 100)

}

// Variables needed from store
const getAppFailed = computed(() => store.getters.getAppFailed);

const getIsLanding = computed(() => store.getters.getIsLanding);

const getIsRotated = computed(() => store.getters.getIsRotated);

const getIsDesktop = computed(
  () => !store.getters.getIsMobile && !store.getters.getIsTablet
);
</script>

<template>
  <div>
    <Fail v-cloak v-if="getAppFailed" />
    <LandingView
      v-if="getIsLanding && !getIsRotated && !getIsDesktop"
      @handle-continue="handleContinue"
    />
    <AppWrapper
      :store="store"
      v-else-if="!getIsLanding || getIsRotated || getIsDesktop"
    />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
