<script setup>
import { onMounted } from "vue";
import audioControl from "../js/audioControl.js";
import AR from "../js/playcanvasAR.js";
import { useStore } from "vuex";
import { inject } from 'vue'
import analytics from "@/js/analytics";
const translate = inject('translate')

const store = useStore();
const reverseCam = () => {

  audioControl.restartAudio("positive");
  AR.app.fire("reverse-camera");
};
const skipScan = () => {
  analytics.analyticsMethod("cta", "click", "Tap Here", "/ar")
  audioControl.restartAudio("positive");
  AR.app.fire("skip-scan");
  store.commit("setScanned", true);

};
</script>

<template>
  <div class="scan-wrapper absolute-fill">
    <div class="scan-prompt">
      <div class="snapdragon-header">
        SCAN THE SNAPDRAGON LOGO ON THE SHIRT TO START
      </div>
    </div>
    <video class="scan-reticle" autoplay muted playsinline crossorigin="anonymous" >
      <source src="assets/vid/reticle_in.webm">
      <source src="assets/vid/reticle_in_hevc.mov">
    </video>

    <div class="skip-prompt snapdragon-header">
      DON'T HAVE THE SHIRT?
    </div>
    <div class="skip-button snapdragon-primary-button" ontouchstart="" v-on:click="skipScan">
      <div>
        Tap Here
      </div>
      <div class="outline"></div>
    </div>

    <img class="button-camera-switch" ontouchstart="" v-on:click="reverseCam" src="../../public/assets/imgs/bttn-swap-camera.png"/>

  </div>
</template>
<style scoped lang="scss">
@import "../sass/Settings";

.scan-wrapper
{
  .scan-prompt {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: vh(40);
    width: vw(286);
    height: vw(62);
    padding-left: vw(8);
    padding-right: vw(8);
    padding-top: vw(11);
    padding-bottom: vw(11);
    background-color: rgba(231, 19, 36, 0.8);
    .snapdragon-header {
      font-size: vw(16);
      line-height: 1.25;
      letter-spacing: vw(0.4);
      text-align: center;
    }
  }
  .scan-reticle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: vw(317);
    height: auto;
  }
  .button-camera-switch {
    position: absolute;
    bottom: vh(26);
    right: vw(20);
    width: vw(40);
    height: auto;
  }
  .skip-prompt {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: vh(75);
    width: 100%;
    text-align: center;

    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    font-size: vw(16);
    letter-spacing: vw(0.4);
  }
  .skip-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: vh(30);
    height: vw(32);
    width: vw(108);
    div {

      font-size: vw(12);
    }
  }
}
</style>
