<script setup>


// grab state
const store = useStore();import { inject } from 'vue'
import audioControl from "@/js/audioControl";
import loadSequence from "@/js/loadSequence";
import { ref, computed } from "vue";
import {useStore} from "vuex";
import InstructionsWrapper from "@/components/InstructionsPage.vue";
import analytics from "@/js/analytics";
const translate = inject('translate')
const loading = ref(false);
const loaded = ref(false);
const isPermissionFinished = computed(() => store.getters.isPermissionFinished);


async function doPermissions() {
  audioControl.restartAudio("positive");
    if (!loading.value) {
      store.dispatch("legalFooterActive", false);
      loading.value = true;
      store.commit("reset");
      store.commit("loadStarted");
      analytics.analyticsMethod("cta", "click", "Continue", "/instructions")
      analytics.changePage("qc:unitedshirt:instructions");

      await loadSequence.asyncPermissions();

      store.commit("isPermissionFinished", true);

      await loadSequence.asyncLoad();

      loading.value = false;
      loaded.value = true;
    }

}

function failPermissions() {
  analytics.analyticsMethod("cta", "click", "Cancel", "/permissions-denied")
  audioControl.restartAudio("negative");
    store.commit("setCameraPermissionsFailed", true);
    store.commit("setCameraPermissionsCancelled", true);
    store.commit("setAppFailed", true);

}

function finishLoading() {
  audioControl.restartAudio("positive");
  store.commit("loadComplete");
  audioControl.restartAudio("reticle");
  analytics.analyticsMethod("cta", "click", "Got it!", "/scan")
  analytics.changePage("qc:unitedshirt:scan");
}

</script>

<template>
  <div class="loader-container absolute-fill">
    <div v-if = "!isPermissionFinished" class = "permissions-request-container">
      <img class="title-text-image" src="../../public/assets/imgs/unitedbysnapdragon-text.png"/>
      <div class="secondary-title-text snapdragon-header">
        DIGITAL SHIRT EXPERIENCE
      </div>
      <img class = "icon-camera" src = "../../public/assets/imgs/icon-camera.png"/>
      <div class = "body-text snapdragon-header">
        This experience uses your Motion Sensors and Camera
      </div>
      <div class = "options-area">
        <div class = "cancel-button snapdragon-primary-button" ontouchstart="" @click = "failPermissions">
          <div>Cancel</div>
          <div class="outline"></div>
        </div>
        <div class = "continue-button snapdragon-primary-button" ontouchstart="" @click = "doPermissions">
          <div>Continue</div>
          <div class="outline"></div>
        </div>
      </div>
      <div class="bottom-anchored">
        <img class="logo-lockup" src="../../public/assets/imgs/snapdragon-menu-lockup-horizontal.png"/>
        <img class="ar-by-8thwall" src="../../public/assets/imgs/ar-by-8thwall-logo.png"/>
      </div>
    </div>
    <InstructionsWrapper v-if = "isPermissionFinished" @close = "finishLoading" :loaded = "loaded">
    </InstructionsWrapper>


    <!-- <PermissionPopup /> -->
  </div>
</template>
