<script setup>
import {inject, onMounted} from 'vue'
  import LegalFooter from "@/components/LegalFooter.vue";
import analytics from "@/js/analytics";
  const translate = inject('translate')
  onMounted(function () {
    analytics.changePage("qc:unitedshirt:homedesktop")
  })
</script>

<template>
  <div class="module-container fail-desktop absolute-fill">
    <div class="upper-area">
      <div class="left-area">
        <div class="title-text snapdragon-header">
          #UnitedBySnapdragon
        </div>
        <div class="secondary-title-text snapdragon-header">
          DIGITAL SHIRT EXPERIENCE
        </div>
        <img src="../../../public/assets/imgs/qr-code.png" class="qr-code">
        <div class="body-text-1 snapdragon-header">
          SCAN THE QR CODE TO GET STARTED
        </div>
        <div class="body-text-2 snapdragon-header">
          *If you’re having trouble scanning the code, please visit unitedshirt.qualcomm.com on your smartphone or tablet.
        </div>
        <img src="../../../public/assets/imgs/snapdragon-menu-lockup-horizontal.png" class="logo-lockup">
      </div>

      <img src="../../../public/assets/imgs/desktop-hero-image.png" class="desktop-hero-image">
    </div>
    <div class="footer">
      <LegalFooter></LegalFooter>
      <img src="../../../public/assets/imgs/ar-by-8thwall-logo-2.png" class="ar-by-8thwall-logo">
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import "../../sass/Settings";
  .fail-desktop.module {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .fail-desktop
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .upper-area {
      position: relative;

      height: vh(934, $design-height-desktop);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-right: vw(82, $design-width-desktop);
      padding-left: vw(82, $design-width-desktop);

      @media (min-aspect-ratio: 7/4) {
        scale: 0.85;
      }
    }
    .footer {
      position: relative;
      height: vh(146, $design-height-desktop);
      width: 100%;
      background-color: #FFFFFF;
      .ar-by-8thwall-logo {
        position: absolute;
        top: vw(24, $design-width-desktop);
        right: vw(24, $design-width-desktop);
        width: vw(103, $design-width-desktop);
        height: auto;
      }
    }
    .left-area {
      position: relative;
      width: 50%;
      height: vw(790, $design-width-desktop);
      left: vw(-45, $design-width-desktop);
      //height: vw(856, $design-width-desktop);
      //top: 50%;
      //transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title-text {
        margin-top: 0;
        font-size: vw(64, $design-width-desktop);
        line-height: 1.17;
        letter-spacing: normal;
      }
      .secondary-title-text {
        margin-top: vh(19, $design-height-desktop);
        font-size: vw(38, $design-width-desktop);
        line-height: 1.82;
        letter-spacing: vw(0.95, $design-width-desktop);
        margin-bottom: 0;
      }
      .qr-code {
        margin-top: vh(74, $design-height-desktop);
        width: vw(170, $design-width-desktop);
        height: auto;
      }
      .body-text-1 {
        margin-top: vh(48, $design-height-desktop);
        font-size: vw(26, $design-width-desktop);
        width: vw(364, $design-width-desktop);
        line-height: 1.15;
        letter-spacing: vw(0.65, $design-width-desktop);
        text-align: center;
      }
      .body-text-2 {
        margin-top: vh(12, $design-height-desktop);
        font-size: vw(12, $design-width-desktop);
        width: vw(364, $design-width-desktop);
        line-height: 1.67;
        letter-spacing: vw(0.12, $design-width-desktop);
        text-align: center;
      }
      .logo-lockup {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: vw(196, $design-width-desktop);
      }
    }

    .desktop-hero-image {
      height: auto;
      width: 50%;
    }


  }
</style>
