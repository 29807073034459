<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import audioControl from "../js/audioControl";
import analytics from "@/js/analytics";
const props = defineProps({
  mediaURL: { default: "", type: String },
  blobURL: { type: Object },
  isVideo: { default: false, type: Boolean },
  hasNativeShare: { default: true, type: Boolean },
  hasDownload: { default: false, type: Boolean },
  hasTapToSave: { default: false, type: Boolean }
});

const store = useStore();
const photoModeVideo = ref(null);
const photoModeImage = ref(null);
const downloadLink = ref(null);

const refreshImage = function () {
  if (props.isVideo) {
    photoModeVideo.value.src = props.mediaURL;
    photoModeVideo.value.load();
    photoModeVideo.value.onloadeddata = function () {
      photoModeVideo.value.play();
      if (props.hasDownload)
        downloadLink.value.href = props.mediaURL;
    }.bind(this);
  } else {
    photoModeImage.value.src = props.mediaURL;
    if (props.hasDownload)
      downloadLink.value.href = props.mediaURL;
  }
};

const nativeShare = async function () {
  analytics.analyticsMethod("cta", "click", "photo-share", "device-native-share")
  let file = props.isVideo
    ? new File([props.blobURL], "recording.mp4", { type: props.blobURL.type })
    : new File([props.blobURL], "screenshot.jpg", { type: props.blobURL.type });
  let shareData = {
    title: "",
    files: [file],
    text: "",
  };
  if (navigator.canShare && navigator.canShare(shareData)) {
    navigator
      .share(shareData)
      .then(() => {
        console.log("share success");
      })
      .catch((error) => console.log("Sharing failed", error));
  }
};

const downloadButton = function () {
  //analytics event if needed
  analytics.analyticsMethod("cta", "click", "photo-download", "device-native-download")
}

const closePhoto = function () {
  store.commit("setPhotoOpen", false);
  audioControl.restartAudio("guiNegative");

  if (iOSMajorVersion.value >= 13) {
    setTimeout(() => {
      // Tell the restart-camera script to stop watching for issues
      window.dispatchEvent(new Event("ensurecameraend"));
    }, 1000);
  }
};

const iOSMajorVersion = computed(() => store.getters.getIOSMajorVersion);
const isIOS = computed(() => store.getters.getIsIOS);
const showLowerButtonArea = computed(
  () => props.hasDownload || props.hasNativeShare
);
const downloadMediaType = computed(
        () => props.isVideo ? "video/mp4" : "image/jpeg"
);
const downloadName = computed(
        () => props.isVideo ? "recording.mp4" : "screenshot.jpg"
);
// Lifecycle functions
onMounted(() => {
  refreshImage();
  analytics.changePage("qc:unitedshirt:photopreview");
});
</script>

<template>
  <div class="photo-display-container">
    <div class="absolute-fill faded-camera-feed" v-on:click="closePhoto" />
    <div
      id="photoContainer"
      class="photo-container"
      :class="{
        'has-native-share': showLowerButtonArea,
      }"
    >
      <img
        id="close-picture-button"
        src="../../public/assets/imgs/icon-close.png"
        v-on:click="closePhoto"
      />
      <video
        v-if="props.isVideo"
        ref="photoModeVideo"
        class="image-display"
        playsinline
        autoplay
        muted
        loop
        :class="{
          'has-native-share': showLowerButtonArea,
        }"
      />
      <img
        v-else
        ref="photoModeImage"
        class="image-display allow-select"
        :class="{
          'has-native-share': props.hasNativeShare,
        }"
      />

    </div>
    <div class="lower-share-container" v-if="showLowerButtonArea"  >
      <a
              v-if="props.hasDownload"
              ref="downloadLink"
              target="_blank"
              :download="downloadName"
              :type="downloadMediaType"
              class="width-half"
              @click="downloadButton"
              ontouchstart=""
      >
        <div class="photo-display-button download-button width-half snapdragon-primary-button">
          <img class="icon-normal" src="../../public/assets/imgs/icon-download.png"/>
        </div>
        <div class="outline"></div>
      </a>

      <div class="photo-display-button share-button snapdragon-primary-button" ontouchstart=""  @click="nativeShare"
           :class="{'width-half': props.hasDownload, 'width-full': !props.hasDownload }">
        <img class="icon-normal" src="../../public/assets/imgs/icon-share.png"/>
        <div class="outline"></div>
      </div>

    </div>
  </div>
</template>
