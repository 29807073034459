<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import Debug from "../components/debug_modules/DebugMenu.vue";
import LegalFooter from "@/components/LegalFooter.vue";
import audioControl from "@/js/audioControl";
import AR from "@/js/playcanvasAR";
import analytics from "@/js/analytics";
const timeout = ref(null);

onUnmounted(() => clearTimeout(timeout.value));

let incrementVisibleElements = function () {
  visibleElements.value++;
}

onMounted(() => {
  analytics.changePage("qc:unitedshirt:home page");

  visibleElements.value = 0;

  setTimeout(incrementVisibleElements, 200)
  setTimeout(incrementVisibleElements, 600)
  setTimeout(incrementVisibleElements, 1000)
  setTimeout(incrementVisibleElements, 1400)
  setTimeout(incrementVisibleElements, 1800)
  setTimeout(incrementVisibleElements, 2200)
})

let signUpClick = () => {
  analytics.analyticsMethod("cta", "click", "Sign Up Now", "https://www.manutd.com/unitedbysnapdragon")
};

let visibleElements = ref(12);

//They only want each event called once
let scrollEventsCalled = {
  "25": false,
  "50": false,
  "75": false,
  "100": false
};
const scrollAnalyticsEvent = (percentString) => {
  if (!scrollEventsCalled[percentString])
  {
    scrollEventsCalled[percentString] = true;
    analytics.analyticsMethod("scroll_track", percentString, "page")
  }
}
const onscrollEvent = (event) => {
  let current = event.target.scrollTop;
  let max = event.target.scrollHeight - event.target.clientHeight;
  let percent = current / max * 100;
  if (percent > 99)
    scrollAnalyticsEvent("100")
  else if (percent > 75)
    scrollAnalyticsEvent("75")
  else if (percent > 50)
    scrollAnalyticsEvent("50")
  else if (percent > 25)
    scrollAnalyticsEvent("25")

  if (percent > 20 && visibleElements.value < 7)
    incrementVisibleElements();

  if (percent > 40 && visibleElements.value < 8)
    incrementVisibleElements();

  if (percent > 60 && visibleElements.value < 9)
    incrementVisibleElements();

  if (percent > 80 && visibleElements.value < 10)
    incrementVisibleElements();
};
</script>

<template>
  <div class="landing-page" @scroll="onscrollEvent">

    <div class="red-area" >
        <img class="title-text-image fades-in" src="../../public/assets/imgs/unitedbysnapdragon-text.png" :class="{visible: visibleElements >= 1}"/>
        <div class="shirt-background fades-in" :class="{visible: visibleElements >= 2}">
            <div class="secondary-title-text snapdragon-header fades-in" :class="{visible: visibleElements >= 3}">DIGITAL SHIRT<br>EXPERIENCE</div>
            <div class = "continue-button snapdragon-primary-button fades-in" ontouchstart="" @click.once="$emit('handle-continue')" :class="{visible: visibleElements >= 4}">
              <div>Let's Go!</div>
              <div class="outline"></div>
            </div>
        </div>
      <img class="logo-lockup fades-in" src="../../public/assets/imgs/snapdragon-menu-lockup-horizontal.png" :class="{visible: visibleElements >= 5}"/>
    </div>

    <div class="fades-in how-to-area" :class="{visible: visibleElements >= 6}">
      <div class="how-it-works-header snapdragon-header">HOW IT WORKS</div>
      <div class="instruction-area">
        <div class="instruction-header snapdragon-header">Scan</div>
        <div class="instruction-body snapdragon-body">Grab the new Manchester United shirt and scan the Snapdragon logo.</div>
      </div>
      <img class="home-instruction-1" src="../../public/assets/imgs/instructions-step1-home.png">
      <div class="left-offset fades-in" :class="{visible: visibleElements >= 7}">
        <img class="home-instruction-2" src="../../public/assets/imgs/instructions-step2-home.png">
        <div class="instruction-area">
          <div class="instruction-header snapdragon-header">Transport to<br>Old Trafford</div>
          <div class="instruction-body snapdragon-body">Watch as the Snapdragon logo comes to life and transforms into a portal taking you inside Old Trafford Stadium.</div>
        </div>
      </div>
      <div class="horizontal-flex fades-in" :class="{visible: visibleElements >= 8}">
        <div class="instruction-area">
          <div class="instruction-header snapdragon-header">Access content<br>and more</div>
          <div class="instruction-body snapdragon-body">Watch epic content, enter for exclusive events, view the new shirt, and more!</div>
        </div>
        <img class="home-instruction-3" src="../../public/assets/imgs/instructions-step3-home.png">
      </div>
    </div>

    <div class="insider-area fades-in" :class="{visible: visibleElements >= 9}">
      <div class="insider-area-title snapdragon-header">BECOME A<br>SNAPDRAGON INSIDER</div>
      <div class="insider-area-body snapdragon-body">Get access to exclusive giveaways,<br>content, and events!</div>
      <a @click="signUpClick" href="https://www.manutd.com/unitedbysnapdragon" target="_blank" class = "sign-up-button snapdragon-primary-button" ontouchstart="">
        <div>Sign Up Now</div>
        <div class="outline"></div>
      </a>
    </div>

    <div class="footer fades-in" :class="{visible: visibleElements >= 10}">
      <LegalFooter></LegalFooter>
    </div>

    <Debug />
  </div>
</template>

<style scoped lang='scss'>
@import '../sass/GlobalClasses';
@import '../sass/Settings';

@keyframes landing-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landing-page {

  overflow-y: scroll;
  height: 100vh;

  .red-area {
    background-color: #e71324;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title-text-image {
      margin-top: vw(20);
      margin-bottom: vw(18);
      width: vw(271);
      height: auto;

      animation: landing-fade 2s;

    }
    .shirt-background {
      background: url("../../public/assets/imgs/home-hero-image.png");
      background-size: cover;
      width: vw(271);
      height: vw(426);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .secondary-title-text {
        margin-bottom: vw(38);
        text-align: center;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        letter-spacing: vw(0.7);
        font-size: vw(28);
      }
      .continue-button{
        position: relative;
        width: vw(164);
        margin-bottom: vw(24);
      }
    }

    .logo-lockup {
      height: auto;
      width: vw(125);
      margin-top: vw(16);
      margin-bottom: vw(17);
    }
  }

  .how-to-area {

    background-color: #fff;
    padding-left: vw(23);
    padding-right: vw(23);
    text-align: left;

    .how-it-works-header {

      margin-top: vw(25);
      margin-bottom: vw(27);
      text-align: center;
      color: #e71324;
      width: 100%;
    }

    .instruction-area {
      .instruction-header {
        color: #e71324;
        font-size: vw(22);
        line-height: 1.27;
        margin-bottom: vw(10);
      }
      .instruction-body {
        font-size: vw(16);
        line-height: 1.38;
        letter-spacing: vw(0.16);
        color: #222;
        width: vw(200);
      }
    }
    .home-instruction-1 {
      margin-top: vw(18);
      width: vw(218);
      height: auto;
    }
    .left-offset {
      margin-left: vw(100);
      .instruction-body {

        width: vw(233);
      }
    }
    .home-instruction-2 {
      margin-top: vw(-21);
      margin-bottom: vw(13);
      width: vw(218);
      height: auto;
    }
    .horizontal-flex {
      margin-top: vw(39);
      width: 100%;
      display: flex;
      flex-direction: row;

      .home-instruction-3 {
        width: vw(124);
        height: auto;
      }
    }
  }
  .insider-area {
    margin-top: vw(40);
    background: url("../../public/assets/imgs/insiders-bg.png");
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .insider-area-title {
      margin-top: vw(27);
      width: vw(334);
      text-align: center;
      line-height: 1.17;
      letter-spacing: vw(0.6);
    }
    .insider-area-body {
      margin-top: vw(21);
      width: vw(301);
      text-align: center;
      line-height: 1.44;
      letter-spacing: vw(0.18);
    }
    .sign-up-button {

      margin-top: vw(32);
      width: vw(190);
      margin-bottom: vw(40);
    }
  }

  .footer {
    margin-top: vw(2);
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .fades-in {
    opacity: 0;
    transition: all 1s;
  }
  .fades-in.visible {
    opacity: 1;
  }


}

</style>
