import qs from "qs";

export const getQueryParam = (name = "") => {
  if (location.search) {
    const key = name.toLowerCase();
    const params = qs.parse(location.search.toLowerCase().slice(1));
    return params[key] ? params[key] : "";
  } else {
    return "";
  }
};

export default {
  legalFooterActive: false,
  firstTimeRun: true,
  isLanding: true,

  //-----LOADING-----

  loadStarted: false,
  loaded: false,

  //-----DEBUG-----

  isDebugEnabled: false,

  //-----PERMISSIONS-----

  hasGyro: true,
  cameraPermissionsRequested: false,
  isCameraPermissionsFailed: false,
  isCameraPermissionsCancelled: false,
  isMotionPermissionsRequested: false,
  isPermissionFinished: false,

  //-----ERRORS-----

  appFailed: false,
  isRotated: false,
  androidUnsupported: false,
  upgradeIOS: false,
  wasCameraAutoRejected: false,

  //-----Device Detect States-----

  isChrome: false,
  isAndroid: false,
  isMobileSafari: false,
  isMobile: false,
  isIOS: false,
  isTablet: false,
  UA: "",
  iOSMajorVersion: 13,
  iOSMinorVersion: 1,
  iOSVersionCutoff: 12,
  isIPhoneX: false,
  isBrowserSupported: false,
  isIGBrowser: false,
  isFBBrowser: false,

  errorScreenOverride: "",

  //-----SCANNER-----

  scanned: false,
  /*
		scanning: false,
		scanFailed: false,
		scanImage: 0,
        */
  insidePortal: false,

  //-----AR TEMPLATE-----

  placed: false,
  shareOpen: false,
  instructionOpen: false,
  isOnboarding: false,

  //-----PHOTO TAKING-----

  flash: false,
  photoOpen: false,

  //-----LANGUAGE-----

  isLanguageSelectorEnlarged: false,
  locale: getQueryParam("locale") || "en-US",

  //-----SCENE CHANGING-----

  isNewSceneLoading: false,

  //-----SCREEN DIMENSIONS-----
  trueVW: 0,
  trueVH: 0,
  designWidthMobile: 375,
  designHeightMobile: 674,
  designWidthDesktop: 1280,
  designHeightDesktop: 800,
};
