<script setup>
import {inject, defineProps, onMounted} from 'vue'
import analytics from "@/js/analytics";
  const props = defineProps({
    hasCautionIcon: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Array,
      array: true,
      default: () => [],
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isMobileRotated: {
      type: Boolean,
      default: false,
    },
    alternateImage: {
      type: String,
      default: ""
    },
    analyticsEvent: {
      type: String,
      default: ""
    }
  });
  const translate = inject('translate')
  onMounted(function () {
    analytics.changePage(props.analyticsEvent);
  })
</script>

<template>
  <div class="module-container">
    <div
      class="content-column"
      style="margin-bottom: 0"
      :class="{
        'is-desktop': props.isDesktop,
        'is-mobile-rotated': props.isMobileRotated,
      }"
    >
      <UncopyableImage
              class="image"
              :src="(props.alternateImage == '') ? './assets/imgs/icon-error.png' : props.alternateImage"
              v-if="props.hasCautionIcon"
              :width="props.isDesktop ? 105 : 56"
              :height="props.isDesktop ? 105 : 56"
              :use-width-scaling="!props.isDesktop && !props.isMobileRotated"
              :use-desktop-proportion="props.isDesktop"
              :mobile-landscape="props.isMobileRotated"
              :class="{
          'is-desktop': props.isDesktop,
          'is-mobile-rotated': props.isMobileRotated,
        }"
      />
      <div v-for="(object, index) in info" v-bind:key="index"
           class="text"
           :class="object.class ? 'text-'+object.class : ''"
           v-html="object.html ? object.html : translate(object.text)">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-container {
  position: relative;
  width: fit-content;
  height: fit-content;

  * {
    pointer-events: all;
  }
}
</style>
